.face-tracker-video {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.mark {
  position: absolute;
}

.mark-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  transition: opacity 1s linear;
}

.filter-on .filter {
  opacity: 0.8;
}

.filter-on .mark {
  width: 70%;
}

.filter-off .filter {
  opacity: 0;
}

.filter-off .mark {
  transition: all 0.1s ease;
}

.mark-transition {
  position: absolute;
  transition-property: width, left, bottom;
  transition-duration: 1s;
  transition-timing-function: ease-out;
}

.filter-on .mark-transition {
  width: 70%;
  left: 15%;
  bottom: 32.9%;
}
